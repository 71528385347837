@font-face {
  font-family: robotoRegular;
  src: url(../fonts/RobotoSlab/RobotoSlab-Light.ttf);
}
@font-face {
  font-family: robotoBold;
  src: url(../fonts/RobotoSlab/RobotoSlab-Bold.ttf);
}
@font-face {
  font-family: changaRegular;
  src: url(../fonts/Changa/Changa-Regular.ttf);
}
html {
  overflow-x: hidden;
}
body {
  font-family: robotoRegular;
  background-color: #e0e0e0;
  letter-spacing: 1.5px;
  line-height: 25px;
  margin: 0;
  overflow-x: hidden;
}
.title,
.subtitle {
  font-family: changaRegular;
}
h1.title {
  font-size: 3em;
  line-height: 40px;
}
h2.title,
label.title {
  font-size: 2em;
  line-height: 30px;
}
label.title {
  margin: 27px 0;
}
h2.subtitle {
  font-size: 1em;
}
.app--content {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1200px;
}
.content,
.content--top {
  width: calc(100% - 60px);
  margin: 0 auto;
}
.content--top {
  margin-top: 78px;
}
.full--section {
  height: calc(100vh - 145px);
}
.orange {
  color: #ff6b26;
}
.green {
  color: #4dc13c;
}
.blue {
  color: #0166c0;
}
.logo--container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 10;
}
.logo--container p {
  letter-spacing: 2px;
  font-size: 12px;
  margin: 0;
}
.logo--container img {
  width: 60px;
  object-fit: contain;
}
.quote--line__top:before {
  content: "";
  margin-bottom: 45px;
  height: 2px;
  width: 40px;
  background: linear-gradient(to right, #ff6b26, #4dc13c, #0166c0);
  display: block;
}
.quote--line__bottom:after {
  content: "";
  margin-top: 45px;
  height: 2px;
  width: 40px;
  background: linear-gradient(to right, #ff6b26, #4dc13c, #0166c0);
  display: block;
}
.quote--line__center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.line--separator__vertical {
  position: absolute;
  max-width: 270px;
  display: flex;
  margin-top: 105px;
  width: 25vw;
  align-self: center;
  justify-self: center;
  transform: rotate(90deg);
  border-bottom: 2px solid;
  border-image: linear-gradient(to right, #ff3d12, #4dc13c, #0166c0) 0 0 1 0;
}
.border--top__gradient {
  border-top: 3px solid;
  border-image: linear-gradient(to right, #ff3d12, #4dc13c, #0166c0) 1 0 0 0;
}
.border--bottom__gradient {
  border-bottom: 3px solid;
  border-image: linear-gradient(to right, #ff3d12, #4dc13c, #0166c0) 0 0 1 0;
}
.mobile {
  display: none !important;
}
.desktop {
  display: flex !important;
}
.tablet {
  display: flex !important;
}
.app--main__content {
  min-height: calc(100vh - 68px);
  display: flex;
  flex-direction: column;
}
.inline--section {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}
.row--section {
  flex: 1 1 100%;
  flex-direction: column;
  display: flex;
}
.small--text {
  margin: 0;
  font-size: 14px;
}
li:focus {
  outline: none;
}
@media screen and (min-width: 767px) {
  .tablet {
    display: flex !important;
  }
}
@media screen and (max-width: 768px) {
  .mobile {
    display: flex !important;
  }
  .desktop {
    display: none !important;
  }

  .line--separator__vertical {
    max-width: none;
  }
}
@media screen and (max-width: 576px) {
  .content {
    width: 100%;
  }
  h1.tite {
    text-align: center;
    margin-bottom: 10px;
  }
}
